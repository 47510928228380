<template>
  <v-container>
    <v-card flat dark color="transparent">
      <v-card-text>
        (coming soon)
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
    data: () => ({
        
    }),
}
</script>

<style scoped>
  .v-card__text {
    font-size: 1em;
    line-height: 2;
  }
</style>